import { Page } from "components/Page";
import React from "react";

const ContactPage = () => {
  return (
    <Page>
      <section className="container mx-auto">
        <div className="md:ml-24 mt-24">
          <h1>CONTACT</h1>
        </div>
      </section>
    </Page>
  );
};

export default ContactPage;
